import { Controller } from 'stimulus';
import { useTransition, useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    useTransition(this, {
      element: this.menuTarget,
      hiddenClass: 'tw-hidden',
    });

    useClickOutside(this);
  }

  toggle() {
    this.toggleTransition();
  }

  hide(event) {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains('tw-hidden')
    ) {
      this.leave();
    }
  }

  clickOutside() {
    if (this.menuTarget) {
      this.leave();
    }
  }
}
