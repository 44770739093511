import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["packageList", "donorPortalCheckbox"];

  updateDonorPortalCheckbox() {
    var selectedPackageValue = $(this.packageListTarget)
      .find("#package option:selected")
      .val();
    var donorPortalCheckboxBool = $(this.donorPortalCheckboxTarget).find(
      "input#donor_portal"
    )[0].checked;
    var donorPortalCheckboxInput = $(this.donorPortalCheckboxTarget).find(
      "input#donor_portal"
    )[0];

    if (donorPortalCheckboxBool != true) {
      if (
        selectedPackageValue == "bam_small_college" ||
        selectedPackageValue == "engage" ||
        selectedPackageValue == "engage-only"
      ) {
        donorPortalCheckboxInput.setAttribute("checked", true);
      }
    }
  }
}
