import { Controller } from 'stimulus';
import { useTransition, useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu', 'icon', 'button'];

  connect() {
    useTransition(this, {
      element: this.menuTarget,
      hiddenClass: 'tw-hidden',

    });
    useClickOutside(this);
  }

  toggleMenu(e) {
    this.toggleTransition();
    this.isOpen = !this.isOpen;

    if (this.iconTarget && !this.isOpen) e.target.closest('button').blur();
  }

  clickOutside() {
    if (this.menuTarget) {
      this.leave();
      this.isOpen = false;
    }
  }
}
