import { Controller } from 'stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu', 'button'];
  connect() {
    useTransition(this, {
      element: this.menuTarget,
      hiddenClass: 'tw-hidden',
    });
  }

  skip() {
    window.location.href = '#main';
    history.pushState('', document.title, window.location.pathname);
    window.scrollTo(-1, -1);
  }

  openNav() {
    if (this.menuTarget) {
      this.toggleTransition();
      this.buttonTarget.setAttribute('aria-expanded', 'true');
    }
  }

  closeNav() {
    if (this.menuTarget) {
      this.leave();
      this.buttonTarget.setAttribute('aria-expanded', 'false');
    }
  }
}
