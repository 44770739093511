import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  connect() {
    const item = this.element.dataset.item;

    this.menu = this.element.querySelector([`[data-menu-for=${item}]`]);

    useClickOutside(this);
  }

  toggleMenu(evt) {
    this.menu.classList.toggle('tw-hidden');
  }

  clickOutside() {
    this.menu.classList.add('tw-hidden');
  }
}
