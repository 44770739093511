import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["preview"]

  preview(evt) {
    let [file] = evt.target.files;

    if (file) {
      this.previewTarget.src = URL.createObjectURL(file);
      this.previewTarget.classList.remove("hidden")
    }
  }
}
