import { Controller } from 'stimulus';
import { useTransition, useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu', 'button', 'icon'];

  connect() {
    if(this.hasMenuTarget){
      useTransition(this, {
        element: this.menuTarget,
        hiddenClass: 'tw-hidden',
      });
    }
    if(this.hasButtonTarget){
      useClickOutside(this, { element: this.buttonTarget });
    }
  }

  toggle(e) {
    if (e.target === this.buttonTarget){
      this.buttonTarget.setAttribute('aria-selected', 'true')
    }

    const isExpanded = this.buttonTarget.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';

    this.buttonTarget.setAttribute('aria-expanded', isExpanded);
    this.iconTarget.classList.toggle('-rotate-90');
    this.toggleTransition();
  }

  clickOutside() {
    this.buttonTarget.setAttribute('aria-selected', 'false')
  }

}
