import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "display", "button" ]

  copy() {
    var value = $(this.sourceTarget).find(".copy-value").text()

    $(this.displayTarget).find("input, textarea").val(value)
    this.enable()
  }

  revert() {
    var value = $(this.displayTarget).find("input, textarea").text() || $(this.displayTarget).find("input, textarea").attr("value")

    $(this.displayTarget).find("input, textarea").val(value)
    $(this.buttonTarget).addClass("disbursement-disable-btn")
  }

  enable() {
    $(this.buttonTarget).removeClass("disbursement-disable-btn").addClass("disbursement-revert-btn")
  }
}
