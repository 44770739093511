import { Controller } from "stimulus";
import { Socket } from "phoenix";

export default class extends Controller {
  static targets = ["coldStorageData"]

  connect() {
    let opportunityId = $(this.coldStorageDataTarget).data("opportunityId");
    let coldStorageTarget = $(this.coldStorageDataTarget).data(
      "coldStorageTarget"
    );
    let siteDomain = document.domain;

    if (siteDomain.includes("localhost")) {
      var socketHost = "ws://" + siteDomain + "/socket";
    } else {
      var socketHost = "wss://" + siteDomain + "/socket";
    }

    let socket = new Socket(socketHost);

    socket.connect();

    let channel = socket.channel(
      "cold_storage_opportunity_" +
        coldStorageTarget +
        ":" +
        opportunityId +
        ":" +
        siteDomain.split(".")[0],
      {}
    );

    channel.join();

    channel.on("index-populated", (_payload) => {
      channel.off("index-populated");
      window.location.reload();
    });
  }
}
